import Layout from 'components/Layout';
import Meta from 'components/Meta';
import { Link } from 'gatsby';
import Lottie from 'lottie-web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import typer from 'typer-js';
import '../styles/homepage.scss';

import ChromeCard from '../components/ChromeCard';
import Questionnaire from '../components/Questionnaire';
import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider';
import TrustedBy from '../components/TrustedBy/TrustedBy';

const Homepage = () => {
  const [webFeedbackTabs, setwebFeedbackTabs] = useState(0);
  // const [bugTrackingTabs, setbugTrackingTabs] = useState(0);
  const [mobileAppTabs, setmobileAppTabs] = useState(0);
  const [customerFeedbackTabs, setcustomerFeedbackTabs] = useState(0);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [activeType, setActiveType] = useState('web');
  const lottieContainer = useRef();

  useEffect(() => {
    Lottie.loadAnimation({
      container: lottieContainer.current,
      path: '/assets/lotties/spinning-border.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  }, []);

  function webFeedbackTabsTrigger(value) {
    return () =>
      setwebFeedbackTabs((state) => (state === value ? value : value));
  }
  // function bugTrackingTrigger(value) {
  //   return () =>
  //     setbugTrackingTabs((state) => (state === value ? value : value));
  // }
  function mobileAppTrigger(value) {
    return () => setmobileAppTabs((state) => (state === value ? value : value));
  }
  function customerFeedbackTrigger(value) {
    return () =>
      setcustomerFeedbackTabs((state) => (state === value ? value : value));
  }

  const toggleQuestionnaire = () => setShowQuestionnaire(!showQuestionnaire);

  const stickyTrigger = useRef();
  const typerBlock = useRef();

  // useEffect(() => {
  //   const stickyWrapper = document.querySelector('.scrollspy-wrapper');
  //   if (stickyTrigger.current) {
  //     window.addEventListener('scroll', () => {
  //       if (window.pageYOffset > stickyTrigger.current?.offsetTop - 200) {
  //         stickyWrapper.classList.add('show');
  //       } else {
  //         stickyWrapper.classList.remove('show');
  //       }
  //     });
  //   }
  // }, []);

  function scrollHandler() {
    const position = window.pageYOffset;
    const phBanner = document.querySelector('.banner-producthunt');
    if (position > 150) {
      phBanner.classList.remove('static-PH');
      phBanner.classList.add('fixed-PH');
    } else {
      phBanner.classList.add('static-PH');
      phBanner.classList.remove('fixed-PH');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  const runTyper = useCallback(() => {
    const kill = new Event('killTyper');
    document.body.dispatchEvent(kill);

    const words = [
      'website',
      // 'web apps review',
      `image`,
      // 'Illustrations review',
      'video',

      'PDF',
    ];

    const instance = typer(typerBlock.current).cursor().line();

    words.forEach((word) => {
      instance

        .empty()
        .continue(word, 100)
        .pause(1000) // Pause for 1 second after each word is typed.
        .back('all', 5); // Erase that word, leaving a blank slate for the next one.
    });

    return instance.repeat(Infinity, true);
  }, []);

  useEffect(() => {
    runTyper();
  }, [runTyper]);

  const WebsiteFeedbackCard = () => (
    <>
      <div
        className="single-product-section"
        data-spy-title="Website Feedback"
        data-spy-id="website-feedback">
        <div className="center">
          <div
            className="prod-tab-buttons"
            style={{
              margin: '0',
              flexWrap: 'wrap',
            }}>
            {[
              {
                id: 0,
                buttonText: 'Add Comment',
              },
              {
                id: 1,
                buttonText: 'Edit Content',
              },
              {
                id: 2,
                buttonText: 'Bug Tracking',
              },
              {
                id: 3,
                buttonText: 'Share with Guests',
              },
              {
                id: 4,
                buttonText: 'Review Web Apps',
              },
            ].map(({ id, buttonText }) => (
              <button
                onClick={webFeedbackTabsTrigger(id)}
                className={webFeedbackTabs === id ? 'active' : ''}
                type="button">
                {buttonText}
              </button>
            ))}
          </div>

          <div className="prod-tab-desc center">
            {[
              {
                id: 0,
                description: (
                  <>
                    Add design feedback by leaving text and video comments on
                    live websites to give contextual feedback, and add
                    attachment files as well.
                  </>
                ),
                img: '/assets/img/comment-website.webp',
              },
              {
                id: 1,
                description: (
                  <>
                    Make real-time CSS edits to visualise the final output. Edit
                    content, change fonts, spacing, margins and many more!
                  </>
                ),
                img: '/assets/img/edit-content-home.webp',
              },
              {
                id: 2,
                description: (
                  <>
                    Report issues directly on live product without the need of
                    screenshots and track all your tickets in Kanban style in
                    one place
                  </>
                ),
                videoURL: 'assets/video/ruttl-bug-tracking-demo.mp4',
              },
              {
                id: 3,
                description: (
                  <>
                    Collect feedback from your external guests/clients with just
                    a link which doesn&apos;t need a signup
                  </>
                ),
                img: '/assets/img/guest-sharing.webp',
              },
              {
                id: 4,
                description: (
                  <>
                    Review and streamline your feedback process by adding
                    comments and edits on your web app.
                  </>
                ),
                img: '/assets/img/web-apps.webp',
              },
            ].map(({ id, description, img, videoURL }) => (
              <div
                key={id}
                className={`prod-tab-pane ${
                  webFeedbackTabs === id ? 'active' : ''
                }`}>
                <p className="center">{description}</p>

                <div className="prod-img">
                  {videoURL ? (
                    <video muted playsInline loop autoPlay src={videoURL} />
                  ) : (
                    <img src={img} alt="" loading="lazy" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );

  const VideoAnnotationCard = () => (
    <>
      <div
        data-spy-title="Video Annotation Tool"
        data-spy-id="video-annotation-tool"
        className="single-product-section"
        style={{ backgroundColor: 'rgba(194, 239, 218, 0.3)' }}>
        {/* <Link href="/video-annotation-tool/" className="view-prod-button">
            View Product
            <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
              <path
                d="m1.031 10.063 3.938-4.22L1.03 1.626"
                stroke="#6552FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link> */}

        <div
          className="prod-tab-buttons"
          style={{
            margin: '0',
            flexWrap: 'wrap',
          }}>
          {[
            {
              id: 0,
              buttonText: 'Record Videos',
            },
            {
              id: 1,
              buttonText: 'Frame Accurate Commenting',
            },
            {
              id: 2,
              buttonText: 'Annotate Videos',
            },
          ].map(({ id, buttonText }) => (
            <button
              onClick={customerFeedbackTrigger(id)}
              className={customerFeedbackTabs === id ? 'active' : ''}
              type="button">
              {buttonText}
            </button>
          ))}
        </div>

        <div className="prod-tab-desc center">
          {[
            {
              id: 0,
              description: (
                <>
                  Record videos directly inside ruttl. Choose to share your
                  camera while recording your screen as well.
                </>
              ),
              img: '/assets/img/record-videos.webp',
            },
            {
              id: 1,
              description: (
                <>
                  Add contextually relevant comments that connect with your
                  video’s timestamp for precise collaboration.
                </>
              ),
              img: '/assets/img/frame-accurate-commenting.webp',
            },
            {
              id: 2,
              description: (
                <>
                  Add both free-hand and shape annotations to communicate even
                  your most challenging feedback.
                </>
              ),
              img: '/assets/img/annotate-videos.webp',
            },
          ].map(({ id, description, img }) => (
            <div
              key={id}
              className={`prod-tab-pane ${
                customerFeedbackTabs === id ? 'active' : ''
              }`}>
              <p className="center">{description}</p>

              <div className="prod-img">
                <img src={img} alt="" loading="lazy" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const PDFImageCard = () => (
    <div
      data-spy-title="Image/PDF Feedback"
      data-spy-id="image-pdf-feedback"
      className="single-product-section"
      style={{ backgroundColor: 'rgba(255, 225, 240, 0.3)' }}>
      <div
        className="prod-tab-buttons"
        style={{
          margin: '0',
          flexWrap: 'wrap',
        }}>
        {[
          {
            id: 0,
            buttonText: 'Add Comments',
          },
          {
            id: 1,
            buttonText: 'Tag Members',
          },
          {
            id: 2,
            buttonText: 'Add Versions',
          },
          {
            id: 3,
            buttonText: 'Annotate images & PDFs',
          },
        ].map(({ id, buttonText }) => (
          <button
            onClick={mobileAppTrigger(id)}
            className={mobileAppTabs === id ? 'active' : ''}
            type="button">
            {buttonText}
          </button>
        ))}
      </div>

      <div className="prod-tab-desc center">
        {[
          {
            id: 0,
            description: (
              <>
                Share pixel-pinned contextual feedback directly on static images
                & PDFs.
              </>
            ),
            img: '/assets/img/add-comments-pdf.webp',
          },
          {
            id: 1,
            description: (
              <>
                Collaborate with your colleagues and assign them deadlines,
                tasks on the go.
              </>
            ),
            img: '/assets/img/tag-members-pdf.webp',
          },
          {
            id: 2,
            description: (
              <>
                Add the updated files as versions to show the changes instantly!
              </>
            ),
            img: '/assets/img/add-versions-pdf.webp',
          },
          {
            id: 3,
            description: (
              <>
                Add both free-hand and shape annotations to your images or PDFs
                to communicate your most challenging feedback.
              </>
            ),
            img: '/assets/img/annotate-pdf-image.webp',
          },
        ].map(({ id, description, img }) => (
          <div
            key={id}
            className={`prod-tab-pane ${mobileAppTabs === id ? 'active' : ''}`}>
            <p className="center">{description}</p>

            <div className="prod-img">
              <img src={img} alt="" loading="lazy" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderFeaturesCard = () => {
    switch (activeType) {
      case 'web':
        return WebsiteFeedbackCard();
      case 'pdf':
        return PDFImageCard();
      case 'video':
        return VideoAnnotationCard();
      default:
        return WebsiteFeedbackCard();
    }
  };
  return (
    <Layout homepage>
      <Meta
        title="Ruttl - Best Design Feedback Tool"
        description="Visual & Design Feedback Tool that helps you review design, content and report bugs on websites, applications, PDFs and images."
        url="https://ruttl.com"
      />

      {showQuestionnaire && (
        <Questionnaire
          showQuestionnaire={showQuestionnaire}
          toggleQuestionnaire={toggleQuestionnaire}
        />
      )}

      <div className="banner-producthunt hidden-mob static-PH">
        <a
          href="https://www.producthunt.com/posts/ruttl-2?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-ruttl&#0045;2"
          target="_blank"
          rel="noopener noreferrer">
          <img
            src="/assets/img/top-post-badge.svg"
            alt="ruttl - Collect&#0032;visual&#0032;feedback&#0032;directly&#0032;on&#0032;websites&#0044;&#0032;PDFs&#0032;&#0038;&#0032;images | Product Hunt"
            style={{ width: 250, height: 54 }}
            width="250"
            height="54"
          />
        </a>
      </div>

      <section className="homepage-hero center">
        <div className="container">
          <div className="home-hero-content">
            <div className="product-hunt-badge ">
              <a
                href="https://www.producthunt.com/posts/ruttl-2?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-ruttl&#0045;2"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src="/assets/img/top-post-badge.svg"
                  alt="ruttl - Collect&#0032;visual&#0032;feedback&#0032;directly&#0032;on&#0032;websites&#0044;&#0032;PDFs&#0032;&#0038;&#0032;images | Product Hunt"
                  style={{ width: 200, height: 44 }}
                  width="200"
                  height="44"
                />
              </a>
            </div>

            <h1 className="mx-auto" style={{ maxWidth: 840 }}>
              One tool for all types of&nbsp;
              <span
                className="blue-text"
                style={{ display: 'inline-block' }}
                ref={typerBlock}
              />
              &nbsp;feedback
            </h1>

            <p>
              ruttl helps product, marketing, sales, and support teams work
              efficiently to grow revenues.
            </p>

            <div className="flex gap-12 justify-content-center flex-wrap">
              <a
                href="https://web.ruttl.com/projects"
                className="button"
                id="get-started-free-home-hero"
                target="_blank"
                rel="noopener noreferrer">
                Get started for free
              </a>
              <a
                href="https://calendly.com/setup-demo-with-ruttl/30min"
                target="_blank"
                rel="noopener noreferrer"
                id="book-demo-home-hero"
                type="button"
                className="button book-demo">
                Book a Demo
              </a>
            </div>

            {/* <div className="hero-review-block">
              <div className="review-stars">
                <img
                  src="/assets/img/stars.svg"
                  alt="Star ratings"
                  width={90}
                  height={19}
                />
             
              </div>

              <div className="review-logos">
                <img
                  src="/assets/img/logo/g2-crowd-full.png"
                  alt="G2 Crowd Logo"
                  width="70px"
                  height="20px"
                />
                <img
                  src="/assets/img/logo/capterra-full.png"
                  alt="Capterra Logo"
                  width="80px"
                  height="18px"
                />
                <img
                  src="/assets/img/logo/get-app-full.png"
                  alt="Get App Logo"
                  width="80px"
                  height="15px"
                />
              </div>
            </div> */}
          </div>
          <TrustedBy />

          {/* <div className="products-list">
            <h2 className="center">Key Features</h2>
            <div className="flex-row justify-content-center our-products">
              <div className="">
                <Link to="/website-feedback-tool/" className="single-product">
                  <img
                    src="/assets/img/website-feedback-icon.svg"
                    alt="Website Feedback Tool Logo"
                    width={37}
                    height={37}
                  />
                  <p>Website Feedback Tool</p>
                </Link>
                <div className="subtext hidden-mob">
                  Comment, edit, and track bugs on live websites
                </div>
              </div>
              <div className="">
                <Link to="/mobile-app-feedback/" className="single-product">
                  <img
                    src="/assets/img/pdf-image.svg"
                    alt="PDF Feedback Logo"
                    width={37}
                    height={37}
                  />
                  <p>Image/PDF Feedback</p>
                </Link>
                <div className="subtext hidden-mob">
                  Collect feedback as comments on your PDFs & images.
                </div>
              </div>
              <div className="">
                <Link to="/video-annotation-tool/" className="single-product">
                  <img
                    src="/assets/img/video-review.svg"
                    alt="Video annotation tool Logo"
                    width={37}
                    height={37}
                    style={{
                      minHeight: '37px',
                    }}
                  />
                  <p>Video Annotation Tool</p>
                </Link>
                <div className="subtext hidden-mob">
                  Review and collaborate on videos
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section style={{ padding: '50px 0px 140px 0px' }}>
        <div className="container">
          <div className="flex-row">
            <div className="flex atlassian-feedback">
              <img
                src="/assets/img/atlassian-logo-round.webp"
                alt="Atlassian Logo"
              />

              <div className="atlassian-feedback-text">
                <p>
                  &quot;We loved using ruttl. The entire process right from
                  sign-up to commenting & managing feedback is so smooth.&quot;
                </p>

                <span>
                  <span className="bold">- Josh Shepherd, </span>
                  Event Tech Strategy at Atlassian
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container hidden-mob" style={{ padding: '50px 0' }}>
        <h2 className="center feature-head">Key Features</h2>
        <div className="features-card">
          <div className="products-list">
            <div className="our-products">
              <button
                type="button"
                className={`single-product ${
                  activeType === 'web' ? 'active' : ''
                }`}
                onClick={() => setActiveType('web')}>
                <img
                  src="/assets/img/website-feedback-icon.svg"
                  alt="Website Feedback Tool Logo"
                  width={37}
                  height={37}
                />
                <p>Website Feedback Tool</p>
                <div className="subtext hidden-mob">
                  Comment, edit, and track bugs on live websites
                </div>
                <Link
                  href="/website-feedback-tool/"
                  className="view-prod-button">
                  View Product
                  <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                    <path
                      d="m1.031 10.063 3.938-4.22L1.03 1.626"
                      stroke="#6552FF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </button>

              <button
                type="button"
                className={`single-product ${
                  activeType === 'pdf' ? 'active' : ''
                }`}
                onClick={() => setActiveType('pdf')}>
                <img
                  src="/assets/img/pdf-image.svg"
                  alt="PDF Feedback Logo"
                  width={37}
                  height={37}
                />
                <p>Image/PDF Feedback</p>
                <div className="subtext hidden-mob">
                  Collect feedback as comments on your PDFs & images.
                </div>
                <Link
                  href="/online-pdf-annotator-markup-software"
                  className="view-prod-button">
                  View Product
                  <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                    <path
                      d="m1.031 10.063 3.938-4.22L1.03 1.626"
                      stroke="#6552FF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </button>

              <button
                type="button"
                className={`single-product ${
                  activeType === 'video' ? 'active' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveType('video');
                }}>
                <img
                  src="/assets/img/video-review.svg"
                  alt="Video annotation tool Logo"
                  width={37}
                  height={37}
                  style={{
                    minHeight: '37px',
                  }}
                />
                <p>Video Annotation Tool</p>
                <div className="subtext hidden-mob">
                  Review and collaborate on videos
                </div>
                <Link
                  href="/online-pdf-annotator-markup-software"
                  className="view-prod-button">
                  View Product
                  <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                    <path
                      d="m1.031 10.063 3.938-4.22L1.03 1.626"
                      stroke="#6552FF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </button>
            </div>
          </div>
          {renderFeaturesCard()}
        </div>
      </section>

      <div className="container hidden-web" ref={stickyTrigger}>
        <div
          className="single-product-section"
          data-spy-title="Website Feedback"
          data-spy-id="website-feedback">
          <div className="center">
            <div className="top-product-icon">
              <img
                src="/assets/img/website-feedback-icon.svg"
                alt="Website Feedback Tool Logo"
              />
            </div>

            <h2 className="mx-auto" style={{ maxWidth: 570 }}>
              Website Feedback Tool
            </h2>

            <Link href="/website-feedback-tool/" className="view-prod-button">
              View Product
              <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                <path
                  d="m1.031 10.063 3.938-4.22L1.03 1.626"
                  stroke="#6552FF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="prod-tab-buttons">
            {[
              {
                id: 0,
                buttonText: 'Add Comment',
              },
              {
                id: 1,
                buttonText: 'Edit Content',
              },
              {
                id: 2,
                buttonText: 'Bug Tracking',
              },
              {
                id: 3,
                buttonText: 'Share with Guests',
              },
              {
                id: 4,
                buttonText: 'Review Web Apps',
              },
            ].map(({ id, buttonText }) => (
              <button
                onClick={webFeedbackTabsTrigger(id)}
                className={webFeedbackTabs === id ? 'active' : ''}
                type="button">
                {buttonText}
              </button>
            ))}
          </div>

          <div className="prod-tab-desc center">
            {[
              {
                id: 0,
                description: (
                  <>
                    Add design feedback by leaving text and video comments on
                    live websites to give contextual feedback, and add
                    attachment files as well.
                  </>
                ),
                img: '/assets/img/comment-website.webp',
              },
              {
                id: 1,
                description: (
                  <>
                    Make real-time CSS edits to visualise the final output. Edit
                    content, change fonts, spacing, margins and many more!
                  </>
                ),
                img: '/assets/img/edit-content-home.webp',
              },
              {
                id: 2,
                description: (
                  <>
                    Report issues directly on live product without the need of
                    screenshots and track all your tickets in Kanban style in
                    one place
                  </>
                ),
                videoURL: 'assets/video/ruttl-bug-tracking-demo.mp4',
              },
              {
                id: 3,
                description: (
                  <>
                    Collect feedback from your external guests/clients with just
                    a link which doesn&apos;t need a signup
                  </>
                ),
                img: '/assets/img/guest-sharing.webp',
              },
              {
                id: 4,
                description: (
                  <>
                    Review and streamline your feedback process by adding
                    comments and edits on your web app.
                  </>
                ),
                img: '/assets/img/web-apps.webp',
              },
            ].map(({ id, description, img, videoURL }) => (
              <div
                key={id}
                className={`prod-tab-pane ${
                  webFeedbackTabs === id ? 'active' : ''
                }`}>
                <p className="center">{description}</p>

                <div className="prod-img">
                  {videoURL ? (
                    <video muted playsInline loop autoPlay src={videoURL} />
                  ) : (
                    <img src={img} alt="" loading="lazy" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          data-spy-title="Image/PDF Feedback"
          data-spy-id="image-pdf-feedback"
          className="single-product-section"
          style={{ backgroundColor: 'rgba(255, 225, 240, 0.3)' }}>
          <div className="center">
            <div className="top-product-icon">
              <img src="/assets/img/pdf-image.svg" alt="PDF Feedback Logo" />
            </div>

            <h2 className="mx-auto" style={{ maxWidth: 570 }}>
              Image/PDF Feedback
            </h2>

            <Link
              href="/online-pdf-annotator-markup-software"
              className="view-prod-button">
              View Product
              <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                <path
                  d="m1.031 10.063 3.938-4.22L1.03 1.626"
                  stroke="#6552FF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="prod-tab-buttons">
            {[
              {
                id: 0,
                buttonText: 'Add Comments',
              },
              {
                id: 1,
                buttonText: 'Tag Members',
              },
              {
                id: 2,
                buttonText: 'Add Versions',
              },
              {
                id: 3,
                buttonText: 'Annotate images & PDFs',
              },
            ].map(({ id, buttonText }) => (
              <button
                onClick={mobileAppTrigger(id)}
                className={mobileAppTabs === id ? 'active' : ''}
                type="button">
                {buttonText}
              </button>
            ))}
          </div>

          <div className="prod-tab-desc center">
            {[
              {
                id: 0,
                description: (
                  <>
                    Share pixel-pinned contextual feedback directly on static
                    images & PDFs.
                  </>
                ),
                img: '/assets/img/add-comments-pdf.webp',
              },
              {
                id: 1,
                description: (
                  <>
                    Collaborate with your colleagues and assign them deadlines,
                    tasks on the go.
                  </>
                ),
                img: '/assets/img/tag-members-pdf.webp',
              },
              {
                id: 2,
                description: (
                  <>
                    Add the updated files as versions to show the changes
                    instantly!
                  </>
                ),
                img: '/assets/img/add-versions-pdf.webp',
              },
              {
                id: 3,
                description: (
                  <>
                    Add both free-hand and shape annotations to your images or
                    PDFs to communicate your most challenging feedback.
                  </>
                ),
                img: '/assets/img/annotate-pdf-image.webp',
              },
            ].map(({ id, description, img }) => (
              <div
                key={id}
                className={`prod-tab-pane ${
                  mobileAppTabs === id ? 'active' : ''
                }`}>
                <p className="center">{description}</p>

                <div className="prod-img">
                  <img src={img} alt="" loading="lazy" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          data-spy-title="Video Annotation Tool"
          data-spy-id="video-annotation-tool"
          className="single-product-section"
          style={{ backgroundColor: 'rgba(194, 239, 218, 0.3)' }}>
          <div className="center">
            <div className="top-product-icon">
              <img
                src="/assets/img/video-review.svg"
                alt="Video Annotation Too,l Logo"
              />
            </div>

            <h2 className="mx-auto" style={{ maxWidth: 570 }}>
              Video Annotation Tool
            </h2>

            <Link href="/video-annotation-tool/" className="view-prod-button">
              View Product
              <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                <path
                  d="m1.031 10.063 3.938-4.22L1.03 1.626"
                  stroke="#6552FF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="prod-tab-buttons">
            {[
              {
                id: 0,
                buttonText: 'Record Videos',
              },
              {
                id: 1,
                buttonText: 'Frame Accurate Commenting',
              },
              {
                id: 2,
                buttonText: 'Annotate Videos',
              },
            ].map(({ id, buttonText }) => (
              <button
                onClick={customerFeedbackTrigger(id)}
                className={customerFeedbackTabs === id ? 'active' : ''}
                type="button">
                {buttonText}
              </button>
            ))}
          </div>

          <div className="prod-tab-desc center">
            {[
              {
                id: 0,
                description: (
                  <>
                    Record videos directly inside ruttl. Choose to share your
                    camera while recording your screen as well.
                  </>
                ),
                img: '/assets/img/record-videos.webp',
              },
              {
                id: 1,
                description: (
                  <>
                    Add contextually relevant comments that connect with your
                    video’s timestamp for precise collaboration.
                  </>
                ),
                img: '/assets/img/frame-accurate-commenting.webp',
              },
              {
                id: 2,
                description: (
                  <>
                    Add both free-hand and shape annotations to communicate even
                    your most challenging feedback.
                  </>
                ),
                img: '/assets/img/annotate-videos.webp',
              },
            ].map(({ id, description, img }) => (
              <div
                key={id}
                className={`prod-tab-pane ${
                  customerFeedbackTabs === id ? 'active' : ''
                }`}>
                <p className="center">{description}</p>

                <div className="prod-img">
                  <img src={img} alt="" loading="lazy" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ChromeCard />
      <TestimonialsSlider />
      <div className="section-gap">
        <div className="container">
          <div className="footer-cta-block">
            <h2>ruttl integrates with your favorite software</h2>

            <Link href="/features/integrations/" className="button">
              View all Integrations
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Homepage;
