import { Link } from 'gatsby';
import React from 'react';
import 'styles/components/ChromeCard.scss';

const CheckMark = () => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="#201B35">
      <path
        opacity=".2"
        d="M18.333 10a8.333 8.333 0 1 1-16.667 0 8.333 8.333 0 0 1 16.667 0Z"
      />
      <path d="M13.359 7.475a.625.625 0 0 1 0 .884l-4.167 4.166a.625.625 0 0 1-.884 0l-1.666-1.667a.625.625 0 1 1 .883-.883l1.225 1.224 1.863-1.862 1.862-1.862a.625.625 0 0 1 .884 0Z" />
    </g>
  </svg>
);

const Stars = () =>
  ['star-1', 'star-2', 'star-3', 'star-4'].map((star) => (
    <div className={`${star} star`} key={star}>
      <svg
        style={{
          color: '#F4F5FF',
        }}
        height={16}
        viewBox="0 0 16 16"
        width={16}>
        <path
          d="M8 0L9.79611 6.20389L16 8L9.79611 9.79611L8 16L6.20389 9.79611L0 8L6.20389 6.20389L8 0Z"
          fill="currentColor"
        />
      </svg>
    </div>
  ));

const Features = [
  'Quick Access to Tools',
  'Enhanced Productivity Features',
  'Seamless Dashboard Integration',
];

const ChromeCard = () => (
  <section
    className="container"
    style={{
      padding: '96px 0',
    }}>
    <div className="supercharge-workflow">
      <h2 className="title">
        Supercharge Your Workflow with Our Chrome Extension
      </h2>
      <ul className="feature-list">
        {Features.map((feature) => (
          <li key={feature} className="feature-item">
            <CheckMark />
            {feature}
          </li>
        ))}
      </ul>
      <Link to="/chrome-extension/" className="download-button">
        Download Chrome Extension
      </Link>
      <div className="logoContainer">
        <img
          src="/assets/img/chrome-icon.svg"
          alt="chrome logo"
          className="chromelogo"
          height={450}
          width={450}
        />
      </div>
      <Stars />
    </div>
  </section>
);

export default ChromeCard;
